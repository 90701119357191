import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from '../../models/User';
import DataRow from '../shared/data-grid/DataRow';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import StatusTag, { StatusVariant } from '../shared/tags/StatusTag';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { useCurrentClient } from '../../global-state/Clients';

export interface PermissionUserItemProps {
  user: User;
  onUserClick: (user: User) => void;
  onUserRemove?: (user: User) => void;
}

const PermissionUserItem: FC<PermissionUserItemProps> = ({ user, onUserClick, onUserRemove }) => {
  const { t } = useTranslation('common');
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const client = useCurrentClient((x) => x.value);

  return (
    <>
      <DataRow
        data-cy={`user-${user.id}`}
        title={`View ${user.firstName || 'user'}`}
        contextMenuItems={[
          {
            title: t('user-status.remove'),
            onClick: () => {
              setShowRemoveConfirm(true);
            },
            hide: !client || !onUserRemove,
          },
        ]}
        onClick={() => onUserClick(user)}
      >
        <div className="text-color-1 w-2/6 font-medium">
          <div className="flex items-center gap-4">
            <ProfileAvatar size={ImageSize.S} user={user} />
            {user.firstName ? user.firstName : `-`}
          </div>
        </div>

        <div className="text-color-1 w-2/6 font-medium">{user.lastName ? user.lastName : `-`}</div>

        <div className="text-color-1 w-2/6 font-medium">{user.email}</div>

        <div className="flex w-1/6 items-center justify-start">
          <div>
            {user.active ? (
              user.verified ? (
                <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GREEN} text={t('user-status.enabled')} />
              ) : (
                <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.ORANGE} text={t('user-status.pending')} />
              )
            ) : (
              <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GRAY} text={t('user-status.deactivated')} />
            )}
          </div>
        </div>
      </DataRow>
      <ModalContext.Provider value={{ open: showRemoveConfirm, modalWidth: 'w-1/4', onClose: () => setShowRemoveConfirm(false) }}>
        <ConfirmationModal
          title={t('remove-user-modal.heading')}
          description={t('remove-user-modal.text')}
          cancelText={t('remove-user-modal.buttons.cancel')}
          confirmText={t('remove-user-modal.buttons.remove')}
          onCancel={() => setShowRemoveConfirm(false)}
          onConfirm={() => {
            onUserRemove && onUserRemove(user);
          }}
          alt
        />
      </ModalContext.Provider>
    </>
  );
};

export default PermissionUserItem;
