import { SupportedLanguage } from '../types/Languages';
import AddressLocation from './AddressLocation';
import { UserClientSpecificCustomData } from './AuthModels';
import { UserRole } from './Role';

export enum UserProfileCustomData {
  jobTitle = 'jobTitle',
  profileImageUrl = 'profileImageUrl',
  isArchitect = 'isArchitect',
}

export enum UserStatuses {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Blocked = 'BLOCKED',
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  language: SupportedLanguage;
  active: boolean;
  fullName: string;
  phoneNumber: string;
  roles: Record<string, string[]>;
  isArchitect: boolean;
  jobTitle: string;
  defaultAccountId?: string;
  address: AddressLocation | null;
  birthday: string | null;
  userImageId?: string;
  clientCustomData: Record<string, UserClientSpecificCustomData>;

  isPasswordSet?: boolean; // scriptex only
  isTwoFactorAuthenticationSet?: boolean; // scriptex only
};

type MinimalUserProperties = 'id' | 'firstName' | 'lastName';
export type MinimalUser = Pick<User, MinimalUserProperties> & Partial<Omit<User, MinimalUserProperties>>;

type AccountUser = {
  id: string;
  accountId: string;
  userId: string;
  invitedBy: string;
  invitePending: boolean;
  inviteAcceptedAt: string;
  active: boolean;
  default: boolean;
  createdAt: string;
  removedAt: string;
};

export type AccountUserResponse = {
  accountName: string;
  roles: UserRole[];
} & AccountUser;

export default User;
